import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.Emulated,
    selector: '[sudoku-grid]',
    styleUrls: ['sudoku-grid.component.scss'],
    templateUrl: 'sudoku-grid.component.html'
})
export class SudokuGridComponent {
    constructor() {}

    public ngOnInit() {}
}
