<div class="toolbar" role="banner">
  <ng-container [ngTemplateOutlet]="yinyang1"></ng-container>
  <span>wluz.com</span>
</div>

<!--  yin-yang pattern 1 -->
<ng-template #yinyang1>
  <a (click)="changeYinYang()">
    <svg height="60" width="60">
      <g [attr.transform]="rotate">
          <svg:circle cx="30" cy="30" r="20" stroke="black" stroke-width="1" fill-opacity="1" />
          <svg:path [attr.d]="d1" stroke="black" fill="white" stroke-width="0" fill-opacity="1" />
          <svg:circle cx="30" cy="20" r="3" stroke="black" stroke-width="0" fill-opacity="1" />
          <svg:circle cx="30" cy="40" r="3" stroke="white" stroke-width="0" fill="white" />
      </g>
    </svg>
  </a>
</ng-template>

<!--  yin-yang pattern 2 is pattern 1 rotated clockwise 90 degrees -->
<!-- <ng-template #yinyang2>
  <a (click)="changeYinYang()">
    <svg height="60" width="60">
      <g>
          <svg:circle cx="30" cy="30" r="20" stroke="black" stroke-width="1" fill-opacity="1" />
          <svg:path [attr.d]="d2" stroke="black" fill="white" stroke-width="0" fill-opacity="1" />
          <svg:circle cx="40" cy="30" r="3" stroke="black" stroke-width="0" fill-opacity="1" />
          <svg:circle cx="20" cy="30" r="3" stroke="white" stroke-width="0" fill="white" />
      </g>
    </svg>
  </a>
</ng-template> -->

<!--  yin-yang pattern 3 is pattern 2 rotated clockwise 90 degrees -->
<!-- <ng-template #yinyang3>
  <a (click)="changeYinYang()">
    <svg height="60" width="60">
      <g>
          <svg:circle cx="30" cy="30" r="20" stroke="black" stroke-width="1" fill-opacity="1" />
          <svg:path [attr.d]="d3" stroke="black" fill="white" stroke-width="0" fill-opacity="1" />
          <svg:circle cx="30" cy="40" r="3" stroke="black" stroke-width="0" fill-opacity="1" />
          <svg:circle cx="30" cy="20" r="3" stroke="white" stroke-width="0" fill="white" />
      </g>
    </svg>
  </a>
</ng-template> -->

<!--  yin-yang pattern 4 is pattern 3 rotated clockwise 90 degrees -->
<!-- <ng-template #yinyang4>
  <a (click)="changeYinYang()">
    <svg height="60" width="60">
      <g>
          <svg:circle cx="30" cy="30" r="20" stroke="black" stroke-width="1" fill-opacity="1" />
          <svg:path [attr.d]="d4" stroke="black" fill="white" stroke-width="0" fill-opacity="1" />
          <svg:circle cx="20" cy="30" r="3" stroke="black" stroke-width="0" fill-opacity="1" />
          <svg:circle cx="40" cy="30" r="3" stroke="white" stroke-width="0" fill="white" />
      </g>
    </svg>
  </a>
</ng-template> -->