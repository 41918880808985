<svg viewBox="0 0 90 90" style="border: green solid;">
    <!-- vertical lines -->
    <svg:path stroke="green" stroke-width="0.1" d="M10 0 V100" />
    <path stroke="green" stroke-width="0.1" d="M20 0 V100" />
    <path stroke="green" stroke-width="0.4" d="M30 0 V100" />
    <path stroke="green" stroke-width="0.1" d="M40 0 V100" />
    <path stroke="green" stroke-width="0.1" d="M50 0 V100" />
    <path stroke="green" stroke-width="0.4" d="M60 0 V100" />
    <path stroke="green" stroke-width="0.1" d="M70 0 V100" />
    <path stroke="green" stroke-width="0.1" d="M80 0 V100" />
    <!-- vertical lines -->
    <path stroke="green" stroke-width="0.1" d="M0 10 H100" />
    <path stroke="green" stroke-width="0.1" d="M0 20 H100" />
    <path stroke="green" stroke-width="0.4" d="M0 30 H100" />
    <path stroke="green" stroke-width="0.1" d="M0 40 H100" />
    <path stroke="green" stroke-width="0.1" d="M0 50 H100" />
    <path stroke="green" stroke-width="0.4" d="M0 60 H100" />
    <path stroke="green" stroke-width="0.1" d="M0 70 H100" />
    <path stroke="green" stroke-width="0.1" d="M0 80 H100" />
    <!--x-axis-->
    <text x="10" y="10" font-size="1">10</text>
    <text x="20" y="10" font-size="1">20</text>
    <text x="30.2" y="10" font-size="1">30</text>
    <text x="40" y="10" font-size="1">40</text>
    <text x="50" y="10" font-size="1">50</text>
    <text x="60.2" y="10" font-size="1">60</text>
    <text x="70" y="10" font-size="1">70</text>
    <text x="80" y="10" font-size="1">80</text>
    <!--y-axis-->
    <text x="10" y="20" font-size="1">20</text>
    <text x="10" y="29.8" font-size="1">30</text>
    <text x="10" y="40" font-size="1">40</text>
    <text x="10" y="50" font-size="1">50</text>
    <text x="10" y="59.8" font-size="1">60</text>
    <text x="10" y="70" font-size="1">70</text>
    <text x="10" y="80" font-size="1">80</text>
    <!--x and y-->
    <text x="28" y="2" font-size="2">X</text>
    <text x="0" y="29" font-size="2">Y</text>
</svg>