import { Component, ViewEncapsulation, OnInit } from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.Emulated,
    selector: 'my-toolbar',
    styleUrls: ['my-toolbar.component.scss'],
    templateUrl: 'my-toolbar.component.html'
})
export class MyToolbarComponent implements OnInit {
    public pattern: number = 1;
    public d1: string = 'M 30 10 a 10 10 0 0 1 0 20 a 10 10 0 1 0 0 20 a 20 20 0 0 1 0 -40';
    // public d2: string = 'M 50 30 a 10 10 0 0 1 -20 0 a 10 10 0 1 0 -20 0 a 20 20 0 0 1 40 0';
    // public d3: string = 'M 30 50 a 10 10 0 0 1 0 -20 a 10 10 0 1 0 0 -20 a 20 20 0 0 1 0 40';
    // public d4: string = 'M 10 30 a 10 10 0 0 1 20 0 a 10 10 0 1 0 20 0 a 20 20 0 0 1 -40 0';
    public rotate: string = 'rotate(0 30 30)';
    // public stroke: string;
    // public fill: string;
    // public strokeWidth: string;
    // public fillOpacity: string;
    // public cx: string;
    // public cy: string;
    // public r: string;

    constructor() { }
    public ngOnInit() {
        // this.stroke='black';
        // this.fill='white';
        // this.strokeWidth='0';
        // this.fillOpacity='1';

        // this.cx='30';
        // this.cy='20';
        // this.r='3';
        // this.stroke='black';
        // this.strokeWidth='0' 
        // this.fill='black';

        // this.cx='30';
        // this.cy='40';
        // this.r='3';
        // this.stroke='white';
        // this.strokeWidth='0' 
        // this.fill = 'white';

    }
    public changeYinYang() {
        this.pattern++;
        // this.pattern = (this.pattern > 4) ? 1 : this.pattern;
        switch (this.pattern) {
            case 4:
                this.rotate = 'rotate(270 30 30)';
                break;

            case 3:
                this.rotate = 'rotate(180 30 30)';
                break;

            case 2:
                this.rotate = 'rotate(90 30 30)';
                break;

            default:
                this.pattern = 1;
                this.rotate = 'rotate(0 30 30)';
                break;
        }
    }
}
